import * as S from './styles';

export default function TextInput({ errorMessage = null, icon: Icon, ...props }: any) {
  return (
    <S.Container className="textfield-container">
      <S.TextFieldContainer hasError={errorMessage !== null}>
        {Icon && <Icon />}
        <input type="text" {...props} />
      </S.TextFieldContainer>
      {errorMessage && <span>{errorMessage}</span>}
    </S.Container>
  );
}
