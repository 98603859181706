/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Container } from './styles';
import { RiBuilding2Line } from 'react-icons/ri';
import { useEnterprise } from '../../contexts/enterprises';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { lighten } from 'polished';
import { BiBuildings } from 'react-icons/bi';
import ButtonLink from '../ButtonLink';

function SelectEnterprise({ closeModal, isLoading, setIsLoading }: any) {
  const [fadeType, setFadeType] = useState<string | null>(null);
  const {
    setSelectedEnterprise,
    enterprises,
    setEnterprises,
    showSelectEnterprise,
    fetchEnterprisesByUser,
    setShowSelectEnterprise,
  } = useEnterprise();

  useEffect(() => {
    if (showSelectEnterprise) {
      setFadeType('fade-in');
      document.body.style.overflowY = 'hidden';
    }
  }, [showSelectEnterprise]);

  useEffect(() => {
    async function getEnterprises() {
      setIsLoading(true);

      setTimeout(() => {
        fetchEnterprisesByUser().finally(() => setIsLoading(false));
      }, 500);
    }

    getEnterprises();

    return () => setEnterprises([]);
  }, [showSelectEnterprise]);

  useEffect(() => {
    if (fadeType === 'fade-out') {
      setTimeout(() => {
        closeModal();
        document.body.style.overflowY = 'auto';
      }, 150);
    }
  }, [fadeType, closeModal]);

  const handleSelectEnterprise = (enterprise: any) => {
    localStorage.setItem('@Levels:selectedEnterprise', JSON.stringify(enterprise));
    setSelectedEnterprise(enterprise);
    handleClose();
  };

  function handleClose() {
    setFadeType('fade-out');
  }

  function handleNavigateToEnterpriseCreate() {
    setShowSelectEnterprise(false);
  }

  return (
    <Container className={`modal ${fadeType}`}>
      <div className="modal-content">
        <div className="modal__header">
          <h1 className="modal__title">Escolher empreendimento</h1>
          <button className="modal-close" onClick={() => handleClose()} title="Cancelar">
            <FiX size={20} />
          </button>
        </div>

        <section className="modal-area">
          <ul className="company__list">
            {isLoading &&
              Array.from(Array(3).keys()).map((key) => (
                <SkeletonTheme
                  key={key}
                  baseColor={'#243751'}
                  highlightColor={lighten(0.1, '#243751')}
                >
                  <li
                    className="company__item"
                    style={{
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Skeleton height={40} width={40} />

                    <div className="company__data">
                      <Skeleton height={10} width={160} />
                      <Skeleton height={8} width={100} style={{ margin: 0 }} />
                    </div>
                  </li>
                </SkeletonTheme>
              ))}
            {!isLoading && enterprises.length > 0
              ? enterprises.map((enterprise: any) => (
                  <li
                    key={enterprise.id}
                    className="company__item"
                    onClick={() => handleSelectEnterprise(enterprise)}
                  >
                    <div className="company__icon">
                      <RiBuilding2Line />
                    </div>

                    <div className="company__data">
                      <strong className="company__name">{enterprise.name}</strong>
                      <span className="company__address">{enterprise.address}</span>
                    </div>
                  </li>
                ))
              : !isLoading && (
                  <div className="company__not-found">
                    <div className="company__not-found-icon">
                      <BiBuildings />
                    </div>

                    <div className="company__not-found-message">
                      <p>
                        <b>Ops!</b> não há empreendimentos
                      </p>
                      <p>Cadastre um novo empreendimento, clicando abaixo.</p>
                    </div>

                    <ButtonLink
                      onClick={handleNavigateToEnterpriseCreate}
                      to="create-enterprise"
                    >
                      CADASTRAR NOVO EMPREENDIMENTO
                    </ButtonLink>
                  </div>
                )}
          </ul>
        </section>
      </div>
    </Container>
  );
}

export default SelectEnterprise;

//row gap
