import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  .field__error {
    color: #ff3b3b;
    display: flex;
    font-size: 1.4rem;
  }
`;

export const TextFieldContainer = styled.div<{
  hasError: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  background: ${({ disabled }) => (disabled ? "#1d2a3c" : " #2b3c54")};
  width: 100%;
  height: 56px;
  border: 1px solid ${(props) => (props.hasError ? "#ff3b3b" : "#151F2E")};
  border-radius: 12px;

  > input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 16px;
    color: #c3dcfd;
    outline: none;
    background: transparent;

    ::placeholder {
      color: #c3dcfd;
      opacity: 0.4;
    }

    :disabled {
      cursor: not-allowed;
    }
  }

  :focus-within {
    border: 1px solid ${(props) => (props.hasError ? "#ff3b3b" : "#1F95FD")};
    transition: border 300ms ease-in-out;
  }
`;

export const FieldTitle = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #c3dcfd;

  display: flex;
  align-items: center;
`;
