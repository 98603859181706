import styled, { keyframes } from 'styled-components';

const fill = (fillPercentage: number, statusBomb: boolean) => keyframes`
  from {
    top: 150vh;
    opacity: 0;
  }
  to {
    top: ${100 - fillPercentage + '%'};
    opacity: 1;
  }
`;

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const EmptyState = styled.div`
  color: #c3dcfd;
  background: #213045;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  width: 100%;
  min-height: calc(100vh - var(--header-height));
  text-align: center;
  line-height: 170%;
`;

export const Background = styled.div`
  width: 100%;
  background: #151f2e;
`;

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - var(--header-height));
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  place-content: center;
  justify-items: center;
  align-items: center;
  gap: 1.6rem;
  padding: 1.6rem;
  max-width: 980px;
  margin: 0 auto;
`;

export const WaterTank = styled.div<{
  fillPercentage: number;
  statusBomb: boolean;
  minimum_percentage: number;
}>`
  cursor: pointer;
  width: 100%;
  height: 350px;
  max-width: 350px;
  border-radius: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  border: 1px solid #3d4c60;
  transition: 200ms;
  background-color: #151f2d;

  :hover {
    background-color: #1e2b3d;
    transition: 200ms;
  }

  div.water-tank-display-name {
    z-index: 200;
    color: #c3dcfd;
    animation: ${fade} 3.5s;
    font-size: 1.6rem;
    margin-top: auto;
    position: absolute;
    bottom: 32px;
    flex-direction: column;
    display: flex;
    row-gap: 0.8rem;

    span {
      font-size: 1.2rem;
      font-weight: 400;
    }
  }

  div.bomb-status {
    position: absolute;
    right: left;
    top: 24px;
    right: 16px;
    animation: ${fade} 3.5s;
    z-index: 1;

    span {
      color: #fff;
      font-size: 1.2rem;
      background-color: ${(props) => (props.statusBomb ? '#1cc34b' : '#FD1E53')};
      padding: 8px 12px;
      border-radius: 8px;
    }
  }

  div.water-tank-info {
    z-index: 0;
    animation: ${fade} 3.5s;
    color: #c3dcfd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    position: relative;
    height: 100%;
    width: 100%;

    :before {
      content: '';
      position: absolute;
      width: 100%;
      height: inherit;
      background: ${(props) =>
        props.fillPercentage < props.minimum_percentage
          ? '#FD1E53'
          : 'linear-gradient(180deg, #267FFF 0%, #3D74B0 100%)'};
      z-index: -1;
      animation: ${(props) => fill(props.fillPercentage, props.statusBomb)} 2s ease-in-out
        forwards;
    }

    span.percent {
      font-size: 3rem;
    }

    strong {
      font-size: 2rem;
      font-weight: 300;
    }
    span {
      font-size: 6rem;
      font-weight: bold;

      div.bomb-status {
        position: absolute;
        right: 16px;
        top: 24px;
        animation: ${fade} 3.5s;

        span {
          color: #fff;
          font-size: 1.2rem;

          background-color: ${(props) => (props.statusBomb ? '#13E94F' : '#FD1E53')};
          padding: 8px 12px;
          border-radius: 8px;
        }
      }

      div.water-tank-info {
        z-index: 0;
        animation: ${fade} 3.5s;
        color: #c3dcfd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        span.percent {
          font-size: 3rem;
        }

        strong {
          font-size: 2rem;
          font-weight: 300;
        }
        span {
          font-size: 6rem;
          font-weight: 500;
        }
      }
    }
  }
`;

export const LogoutButton = styled.div`
  position: fixed;
  color: #1e2d41;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 60px;
  background-color: #ffffff;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 200ms;
  svg {
    font-size: 2rem;
  }
  :hover {
    filter: brightness(80%);
  }
`;
