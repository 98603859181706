import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthContext from '../contexts/auth';

import Login from '../pages/Login';
import Home from '../pages/Home';
import WatertankGraphic from '../pages/WatertankGraphic';
import CreateEnterprise from '../pages/CreateEnterprise';
import { isEmpty } from 'lodash';

function CustomRoute({ isPrivate, ...rest }: any) {
  const { loading } = useContext(AuthContext);

  const authenticated = !isEmpty(
    JSON.parse(localStorage.getItem('@Levels:token') ?? '{}')
  );

  if (loading) {
    return <h1>Carregando...</h1>;
  }

  if (authenticated && rest.path === '/login') {
    return <Redirect to="/dashboard" />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/login" component={Login} />
      <CustomRoute isPrivate exact path="/dashboard" component={Home} />
      <CustomRoute isPrivate path="/create-enterprise" component={CreateEnterprise} />
      <CustomRoute isPrivate exact path="/grafico" component={WatertankGraphic} />
      <Redirect from="/" to="/login" />
    </Switch>
  );
}
