import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api as waterTankApi } from '../services/waterTankApi';
import { useContext } from 'react';

interface WaterTankProps {
  enterprise_id: string;
  liters: number;
  bomb_activated: boolean;
  fetchWaterTanks: () => Promise<void>;
}

export type WaterTank = {
  enterprise_id: string;
  liters: string;
  bomb_activated: boolean;
  percentage: number;
  id: string;
  name: string;
  liters_capacity: number;
  online: boolean;
  iot_device_id: string;
  minimum_percentage: number;
  water_tank_data: {
    percentage: number;
  };
};

const WaterTankContext: React.Context<WaterTankProps | any> = createContext({});

export const useWaterTank = () => useContext(WaterTankContext);

export const WaterTankProvider: any = ({ children }: any) => {
  const [waterTanks, setWaterTanks] = useState<WaterTank | any[]>([]);

  useEffect(() => {
    const waterTanks = localStorage.getItem('@Levels:waterTank');

    if (waterTanks) {
      setWaterTanks(JSON.parse(waterTanks));
    }
  }, []);

  async function fetchWaterTanks(enterpriseId: number) {
    try {
      const { data: waterTanksResponse } = await waterTankApi.get(
        `/water-tanks?enterprise_id=${enterpriseId}&last_data=true`
      );

      localStorage.setItem('@Levels:waterTank', JSON.stringify(waterTanksResponse));

      setWaterTanks(waterTanksResponse);
    } catch (error: any) {
      toast.error('Ocorreu um erro ao trazer empreendimentos 🙁', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <WaterTankContext.Provider value={{ waterTanks, fetchWaterTanks }}>
      {children}
    </WaterTankContext.Provider>
  );
};

export default WaterTankContext;
