import { createContext, useState, useContext, useEffect } from 'react';
import { api as enterprisesApi } from '../services/enterprisesApi';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import isEmpty from 'lodash/isEmpty';

interface EnterprisesContextProps {
  fetchEnterprisesByUser: () => Promise<any>;
  showSelectEnterprise: boolean;
  setShowSelectEnterprise: (showSelectEnterprise: boolean) => void;
}

const EnterprisesContext: React.Context<EnterprisesContextProps | any> = createContext(
  {}
);

export const useEnterprise = () => useContext(EnterprisesContext);

export const EnterprisesProvider: any = ({ children }: any) => {
  const [enterprises, setEnterprises] = useState<EnterprisesContextProps | any[]>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<any>(() => {
    const enterprise = JSON.parse(
      localStorage.getItem('@Levels:selectedEnterprise') ?? '{}'
    );

    return !isEmpty(enterprise) ? enterprise : null;
  });
  const [showSelectEnterprise, setShowSelectEnterprise] = useState<any>(() => {
    if (isEmpty(selectedEnterprise)) return true;

    return false;
  });

  const { authenticated, loading } = useAuth();

  useEffect(() => {
    if (!authenticated && !loading) {
      setSelectedEnterprise(null);
      setEnterprises([]);
    }
  }, [authenticated]);

  async function fetchEnterprisesByUser() {
    try {
      const { data: enterprisesResponse } = await enterprisesApi.get('/enterprises');
      setEnterprises(enterprisesResponse);
      Promise.resolve(enterprisesResponse);
    } catch (error: any) {
      toast.error('Ocorreu um erro ao trazer empreendimentos 🙁', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <EnterprisesContext.Provider
      value={{
        enterprises,
        fetchEnterprisesByUser,
        setSelectedEnterprise,
        selectedEnterprise,
        showSelectEnterprise,
        setShowSelectEnterprise,
        setEnterprises,
      }}
    >
      {children}
    </EnterprisesContext.Provider>
  );
};

export default EnterprisesContext;
