import styled from 'styled-components';

export const Container = styled('button')`
  position: fixed;
  color: #1e2d41;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 60px;
  background-color: #ffffff;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 200ms;
  svg {
    font-size: 2rem;
  }
  :hover {
    filter: brightness(80%);
  }
`;
