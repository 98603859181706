import { createContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { api as authApi } from '../services/authApi';
import { api as userApi } from '../services/userApi';
import { api as enterprisesApi } from '../services/enterprisesApi';
import { api as waterTankApi } from '../services/waterTankApi';

interface AuthContextData {
  authenticated: boolean;
  loading: boolean;
  user: any;
  handleLogin(email: string, password: string): Promise<void>;
  handleLogout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function handleLogout() {
  localStorage.removeItem('@Levels:user');
  localStorage.removeItem('@Levels:token');
  localStorage.removeItem('@Levels:waterTank');
  localStorage.removeItem('@Levels:selectedEnterprise');

  window.location.href = '/login';
}

export const AuthProvider: any = ({ children }: any) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const authenticated = !!user;

  useEffect(() => {
    const { access_token: token } = JSON.parse(
      localStorage.getItem('@Levels:token') ?? '{}'
    );

    if (token) {
      setLoading(true);
      userApi
        .get('/users/account')
        .then((response) => {
          const { name, email } = response.data;

          setUser({ name, email });
        })
        .catch(() => {
          handleLogout();
        });
    }

    setLoading(false);
  }, []);

  async function handleLogin(email: string, password: string) {
    try {
      const {
        data: { user, access_token, refresh_token },
      } = await authApi.post('/auth/sessions', {
        email,
        password,
      });

      localStorage.setItem('@Levels:user', JSON.stringify(user));
      localStorage.setItem(
        '@Levels:token',
        JSON.stringify({ access_token, refresh_token })
      );

      setUser(user);
    } catch (error: any) {
      //Quando api tiver em português usar error.response.data.message
      toast.error('Email ou senha incorretos 🙁', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <AuthContext.Provider
      value={{ authenticated, loading, user, handleLogout, handleLogin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
