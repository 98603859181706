import React, { useContext } from 'react';
import { useFormik } from 'formik';

import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import AuthContext from '../../contexts/auth';

import * as S from './styles';
import { FiMail, FiLock, FiLogIn } from 'react-icons/fi';

import { ReactComponent as WavesSvg } from '../../assets/waves.svg';
import { ReactComponent as LogoSvg } from '../../assets/logo.svg';

import { useHistory } from 'react-router-dom';

import * as yup from 'yup';

const Login: React.FC = () => {
  const { handleLogin } = useContext(AuthContext);
  const history = useHistory();

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email('Deve ser um e-mail válido')
      .required('Você deve preencher o e-mail'),
    password: yup
      .string()
      .min(6, 'A senha deve ter no mínimo 6 caracteres')
      .required('Você deve preencher a senha'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: true,
    isInitialValid: false,
    validationSchema: loginSchema,
    onSubmit: (values, actions) => {
      // const onSuccess = () => history.push('/dashboard');
      handleLogin(values.email, values.password);
      actions.setSubmitting(false);
    },
  });

  return (
    <S.Container>
      <LogoSvg className="logo" />

      <S.Card>
        <S.CardHeader>
          <h1>
            <strong>Olá</strong>! Seja bem-vindo
          </h1>
          <span>Entre com suas credenciais para acessar sua conta</span>
        </S.CardHeader>
        <S.Form>
          <TextInput
            icon={FiMail}
            name="email"
            type="email"
            placeholder="Digite seu e-mail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errorMessage={formik.touched.email && formik.errors.email}
          />
          <TextInput
            icon={FiLock}
            name="password"
            type="password"
            placeholder="Digite sua senha"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errorMessage={formik.touched.password && formik.errors.password}
          />

          <Button
            disabled={!formik.isValid || formik.isSubmitting}
            type="button"
            onClick={formik.handleSubmit}
          >
            <FiLogIn />
            Entrar na plataforma
          </Button>
        </S.Form>

        <S.ForgotPassword>
          <span>
            Esqueceu sua senha? <a href="!#">Recuperar senha</a>
          </span>
        </S.ForgotPassword>
      </S.Card>

      <WavesSvg className="waves" />
    </S.Container>
  );
};

export default Login;
