import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
:root {
    --header-height: 80px;

    @media(max-width: 768px) {
        --header-height: 60px;
    }
}
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
    }

    body {
        font-family: 'Inter', sans-serif;
        font-size: 1.6rem;
        -webkit-font-smoothing: antialiased;
        background: #f2f6ff;
    }

    input, button, textarea {
        font-family: 'Inter', sans-serif;
    }

    ul {
        list-style: none;
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: 'Inter', sans-serif;
    }

    button, input, a {
        font-size: 1.6rem;
    }


    .bd-grid {
        max-width: 1024px;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 100%;
            grid-template-columns: 100%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    @media screen and (min-width: 1024px) {
        .bd-grid {
            margin-left: auto;
            margin-right: auto;
        }
    }

    a {
        text-decoration: none;
    }
`;

export default GlobalStyle;