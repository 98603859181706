import styled from "styled-components";

import {Link} from 'react-router-dom'

export const Button = styled(Link)`
    width: 100%;
    height: 56px;
    border: none;
    background: #1F95FD;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 500;
    text-decoration: none;
    padding: 1.2rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: filter 200ms;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        margin-right: 12px;
        font-size: 2rem;
    }

    :disabled {
        opacity: 0.75;
        cursor: not-allowed;
    }

    :hover {
        filter: brightness(120%);
    }
`;