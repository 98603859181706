/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import SelectEnterprise from '../../components/SelectEnterprise';
import { useWaterTank, WaterTank } from '../../contexts/waterTank';
import { useAuth } from '../../hooks/useAuth';
import * as S from './styles';
import { useEnterprise } from '../../contexts/enterprises';
import { useHistory } from 'react-router-dom';

const Home: React.FC = () => {
  const { handleLogout } = useAuth();
  const { waterTanks, fetchWaterTanks } = useWaterTank();
  const {
    fetchEnterprisesByUser,
    selectedEnterprise,
    setShowSelectEnterprise,
    showSelectEnterprise,
  } = useEnterprise();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const hasSelectedEnterpise = selectedEnterprise !== null;

  useEffect(() => {
    if (hasSelectedEnterpise) {
      (async () => {
        setIsLoading(true);
        setTimeout(async () => {
          fetchEnterprisesByUser().finally(() => setIsLoading(false));
          setIsLoading(false);
        }, 800);
      })();
    }
  }, []);

  useEffect(() => {
    async function getWaterTanks() {
      setIsLoading(true);
      await fetchWaterTanks(selectedEnterprise.id);
      setIsLoading(false);
    }

    const interval = setInterval(function () {
      if (selectedEnterprise !== null) {
        getWaterTanks();
      }
    }, 30000);

    if (selectedEnterprise !== null) {
      getWaterTanks();
    } else {
      setShowSelectEnterprise(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [selectedEnterprise]);

  const handleCloseModal = () => {
    setShowSelectEnterprise(false);
  };

  return (
    <S.Background>
      {showSelectEnterprise && (
        <SelectEnterprise
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          modalIsOpen={showSelectEnterprise}
          closeModal={handleCloseModal}
        />
      )}
      {waterTanks.length === 0 && !isLoading ? (
        <S.EmptyState>Nenhum dispositivo encontrado neste empreendimento 😣</S.EmptyState>
      ) : (
        <S.Container>
          {waterTanks.map((waterTank: WaterTank) => (
            <S.WaterTank
              key={waterTank.id}
              statusBomb={waterTank.online}
              onClick={() =>
                history.push({
                  pathname: '/grafico',
                  state: { watertank: waterTank },
                })
              }
              fillPercentage={waterTank.water_tank_data.percentage}
              minimum_percentage={waterTank.minimum_percentage}
              className="cup"
            >
              <div className="bomb-status">
                <span>
                  {waterTank.online ? 'Dispositivo online' : 'Dispositivo offline'}
                </span>
              </div>

              <div className="water-tank-info">
                <span>
                  {Math.floor(waterTank.water_tank_data.percentage)}
                  <span className="percent">%</span>
                </span>
              </div>

              <div className="water-tank-display-name">
                <strong>{waterTank.name}</strong>
                <span>ID: {waterTank.iot_device_id}</span>
              </div>
            </S.WaterTank>
          ))}
        </S.Container>
      )}
      <S.LogoutButton onClick={handleLogout}>
        <FiLogOut />
      </S.LogoutButton>
    </S.Background>
  );
};

export default Home;
