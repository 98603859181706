import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: var(--header-height);
  position: sticky;
  top: 0;
  left: 0;
  padding: 0 1.5rem;
  background-color: #243751;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 768px) {
    position: relative;
  }

  .nav__perfil {
    display: inline-flex;
    row-gap: 0.5rem;
    column-gap: 1.2rem;
    flex-direction: column;
    color: #fff;

    &__greeting {
      font-size: 1.4rem;
      font-weight: 400;
    }

    &__name {
      font-weight: 500;
      font-size: 1.6rem;
    }
  }

  .header__logo {
    width: 100px;
    margin: 0;
  }

  .header__toggle {
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .nav {
      position: fixed;
      top: 0;
      left: -100%;
      background-color: #243751;
      color: #ffffff;
      width: 100%;
      height: 100vh;
      padding: 1.5rem 0;
      z-index: 100;
      -webkit-transition: 0.5s;
      transition: 0.5s;

      .nav__link {
        justify-content: flex-start;
        padding: 0;
      }
    }

    .nav__perfil {
      order: -2;
    }

    .enterprise__button {
      justify-content: flex-start;
      order: -1;
      margin-top: 40px;
    }

    .nav__menu {
      margin-top: 0;
    }
  }

  .enterprise__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;
    font-size: 14px;
    strong {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .enterprise__button {
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    background: #243751;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    column-gap: 8px;

    :hover {
      color: ${darken(0.1, '#fff')};

      svg {
        color: ${darken(0.1, '#fff')};
      }
    }

    svg {
      color: #fff;
      font-size: 1.75rem;
      margin-right: 8px;
    }
  }

  .nav__content {
    height: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav__close {
    position: absolute;
    right: 1.5rem;
    font-size: 1.3rem;
    padding: 0.25rem;
    background-color: #3a5375;
    border-radius: 50%;
    cursor: pointer;
  }

  .nav__img {
    display: flex;
    justify-content: center;
    padding-top: 0.25rem;
    margin-bottom: 0.8rem;
    width: 56px;
    height: 56px;
    background-color: var(--first-color);
    border-radius: 50%;
    overflow: hidden;
  }

  .nav__img img {
    width: 46px;
  }

  .nav__name {
    display: block;
    color: #ffffff;
  }

  .nav__profesion {
    font-size: var(--small-font-size);
  }

  .nav__item {
    margin: 2.5rem 0;
  }

  .nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #b0bed2;
    padding: 0 24px;

    svg {
      margin-right: 12px;
    }

    :hover {
      color: #ffffff;
    }

    &.nav__link--active {
      color: #ffffff;
    }
  }

  .nav__social {
    padding-top: 0.5rem;
  }

  .nav__social-icon {
    font-size: 1.2rem;
    color: #ffffff;
    margin-right: 1rem;
  }

  .nav__social-icon:hover {
    color: var(--first-color);
  }

  /*Aparecer menu*/
  .show {
    left: 0;
  }

  /*Active menu*/
  .active {
    color: var(--first-color);
  }

  @media screen and (min-width: 768px) {
    .header {
      height: calc(var(--header-height) + 1rem);
    }
    .header__logo,
    .header__toggle {
      display: none;
    }
    .nav {
      width: 100%;
    }
    .nav__content {
      grid-template-columns: repeat(3, max-content);
      grid-template-rows: 1fr;
      column-gap: 1rem;
      display: grid;
      justify-content: space-between;
      row-gap: 2rem;
      align-items: center;
    }
    .nav__close,
    .nav__profesion {
      display: none;
    }

    .nav__img {
      width: 32px;
      height: 32px;
      margin-right: 0.5rem;
      margin-bottom: 0;
      align-items: center;
    }
    .nav__img img {
      width: 26px;
    }
    .nav__list {
      display: flex;
    }
    .nav__item {
      margin: 0 0.25rem;
    }
    .nav__menu {
      align-self: center;
    }
    .nav__link {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #b0bed2;
      padding: 0 24px;
      justify-content: flex-start;
    }
    .nav__link:hover {
      background-color: var(--first-color);
    }
    .active {
      background-color: var(--first-color);
      color: #ffffff;
    }
  }
`;
