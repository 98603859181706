import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';

import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import { api } from '../../services/enterprisesApi';
import * as S from './styles';

interface ICreateEnterprise {
  name: string;
  zip_code: string;
  state: string;
  city: string;
  district: string;
  address: string;
}

const CreateEnterprise: React.FC = () => {
  const createEnterpriseSchema = yup.object().shape({
    name: yup.string().required('Você deve preencher o e-mail'),
    zip_code: yup.string().required('Você deve preencher com o seu CEP'),
    state: yup.string().max(2).required('Você deve preencher com o seu Estado'),
    city: yup.string().required('Você deve preencher com a sua Cidade'),
    district: yup.string().required('Você deve preencher com o seu Bairro'),
    address: yup.string().required('Você deve preencher com o seu Endereço'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      state: '',
      city: '',
      zip_code: '',
      district: '',
      address: '',
    },
    validateOnBlur: true,
    isInitialValid: false,
    validationSchema: createEnterpriseSchema,
    onSubmit: async (values, actions) => {
      const { address, city, district, name, state, zip_code } = values;
      try {
        await handleCreateEnterprise({
          address,
          city,
          district,
          name,
          state,
          zip_code,
        });
      } catch (error) {
        actions.resetForm();
      }
      actions.setSubmitting(false);
    },
  });

  async function handleCreateEnterprise({
    address,
    city,
    district,
    name,
    state,
    zip_code,
  }: ICreateEnterprise) {
    try {
      const formattedZipCode = zip_code.replace(/[^a-zA-Z0-9 ]/g, '');

      await api.post('/enterprises', {
        address,
        city,
        district,
        name,
        state,
        zip_code: formattedZipCode,
      });

      toast.success('Empreendimento cadastrado', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error('Erro ao cadastrar empreendimento', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  async function onBlurCep(event: any) {
    const { setFieldValue, setFieldError, values } = formik;

    const cep = values.zip_code;

    formik.handleBlur(event);
    try {
      await axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`).then((response) => {
        const { data } = response;

        setFieldValue('state', data.state);
        setFieldValue('city', data.city);
        setFieldValue('district', data.neighborhood);
        setFieldValue('address', data.street);
      });
    } catch (err) {
      if (cep) {
        setFieldError('zip_code', 'CEP não encontrado');
      }
    }
  }

  return (
    <S.Container>
      <S.SideNavigation>
        <a className="nav__link nav__active" href="!#">
          Novo Empreendimento
        </a>
        <a className="nav__link nav__disabled" href="!#">
          Meus Dados
          <span className="nav__soon nav__disabled">(em breve)</span>
        </a>
        <a className="nav__link nav__disabled" href="!#">
          Vincular novo dispositivo
          <span className="nav__soon">(em breve)</span>
        </a>
        <a className="nav__link nav__disabled" href="!#">
          Assinatura
          <span className="nav__soon">(em breve)</span>
        </a>
      </S.SideNavigation>

      <S.Form className="formEnterprise">
        <S.FormTitle>Novo empreendimento</S.FormTitle>

        <S.FormGroup>
          <FormInput
            fieldTitle="Nome empreendimento"
            placeholder="Digite o nome do empreendimento"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            errorMessage={formik.touched.name && formik.errors.name}
          />
        </S.FormGroup>

        <S.FormGroup>
          <FormInput
            fieldTitle="CEP"
            placeholder="Digite o CEP"
            name="zip_code"
            onChange={formik.handleChange}
            onBlur={(event: any) => onBlurCep(event)}
            mask="cep"
            value={formik.values.zip_code}
            errorMessage={formik.touched.zip_code && formik.errors.zip_code}
          />

          <FormInput
            fieldTitle="Rua/Avenida"
            placeholder="Digite o Endereço"
            name="address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            errorMessage={formik.touched.address && formik.errors.address}
          />
        </S.FormGroup>

        <S.FormGroup>
          <FormInput
            fieldTitle="Bairro"
            placeholder="Digite o bairro"
            name="district"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.district}
            errorMessage={formik.touched.district && formik.errors.district}
          />

          <FormInput
            fieldTitle="Cidade"
            placeholder="Cidade"
            name="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            errorMessage={formik.touched.city && formik.errors.city}
            disabled={true}
          />

          <FormInput
            fieldTitle="UF"
            placeholder="UF"
            maxLength="2"
            name="state"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            errorMessage={formik.touched.state && formik.errors.state}
            disabled={true}
          />
        </S.FormGroup>

        <Button
          disabled={!formik.isValid || formik.isSubmitting}
          type="button"
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? 'Carregando...' : 'Salvar'}
        </Button>
      </S.Form>
    </S.Container>
  );
};

export default CreateEnterprise;
