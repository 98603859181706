import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';

import { EnterprisesProvider } from './contexts/enterprises';
import GlobalStyle from './styles/global';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WaterTankProvider } from './contexts/waterTank';
import { useAuth } from './hooks/useAuth';
import Header from './components/Header';
import LogoutButton from './components/LogoutButton';

function App() {
  const { authenticated } = useAuth();

  return (
    <EnterprisesProvider>
      <WaterTankProvider>
        <GlobalStyle />
        <Router>
          {authenticated && <LogoutButton />}
          {authenticated && <Header />}
          <ToastContainer />
          <Routes />
        </Router>
      </WaterTankProvider>
    </EnterprisesProvider>
  );
}

export default App;
