import axios, { AxiosError } from 'axios';
import { handleLogout } from '../contexts/auth';

interface AxiosErrorResponse {
  message?: string;
}

type AuthApiResponse = {
  access_token: string;
  refresh_token: string;
};

let isRefreshing = false;
let failedRequestQueue: any = [];

export function setupAPI(url: any) {
  const api = axios.create({
    baseURL: url,
  });

  api.interceptors.request.use((request) => {
    const headers = request.headers ?? {};
    // Buscando seu token salvo no localstorage ou qualquer outro local
    const token = JSON.parse(localStorage.getItem('@Levels:token') || '{}');

    if (token) {
      // Authorization geralmente é o header padrão para envio de token, mas isso não é uma regra. O endpoint pode requisitar outro header.
      headers['Authorization'] = `Bearer ${token?.access_token}`;
    }

    request.headers = headers;
    // Este return é necessário para continuar a requisição para o endpoint.
    return request;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError<AxiosErrorResponse>) => {
      if (error?.response?.status === 401) {
        const { refresh_token } = JSON.parse(
          localStorage.getItem('@Levels:token') || '{}'
        );

        const originalConfig: any = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          axios
            .post(`${process.env.REACT_APP_AUTH_API_URL}/auth/refresh-token`, {
              refresh_token,
            })
            .then((response) => {
              const { access_token: token, refresh_token: newRefreshToken } =
                response.data as AuthApiResponse;

              localStorage.setItem(
                '@Levels:token',
                JSON.stringify({ access_token: token, refresh_token: newRefreshToken })
              );

              api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

              failedRequestQueue.forEach((request: any) => request.onSuccess(token));

              failedRequestQueue = [];
            })
            .catch((err) => {
              failedRequestQueue.forEach((request: any) => request.onFailure(err));

              failedRequestQueue = [];
              handleLogout();
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestQueue.push({
            onSuccess: (token: string) => {
              originalConfig.headers['Authorization'] = `Bearer ${token}`;

              resolve(api(originalConfig));
            },

            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }

      return Promise.reject(error);
    }
  );

  return api;
}
