import React, { useEffect, useState } from 'react';

import * as S from './styles';
import { useAuth } from '../../hooks/useAuth';
import { RiBuilding2Line } from 'react-icons/ri';
import { useEnterprise } from '../../contexts/enterprises';
import { FiBarChart2, FiMenu, FiX } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import SelectEnterprise from '../SelectEnterprise';
import { useWaterTank } from '../../contexts/waterTank';
import levelsLogoImg from '../../assets/levels-logo.svg';

const Header: React.FC = () => {
  const { user } = useAuth();
  const { selectedEnterprise, setShowSelectEnterprise, showSelectEnterprise } =
    useEnterprise();
  const { fetchWaterTanks } = useWaterTank();
  const [isLoading, setIsLoading] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    async function getWaterTanks() {
      setIsLoading(true);
      await fetchWaterTanks(selectedEnterprise.id);
      setIsLoading(false);
    }

    const interval = setInterval(function () {
      if (selectedEnterprise !== null) {
        getWaterTanks();
      }
    }, 30000);

    if (selectedEnterprise !== null) {
      getWaterTanks();
    } else {
      setShowSelectEnterprise(!showSelectEnterprise);
    }

    return () => {
      clearInterval(interval);
    };
  }, [selectedEnterprise]);

  const handleCloseModal = () => {
    setShowSelectEnterprise(!showSelectEnterprise);
  };

  return (
    <>
      <S.Container className="header">
        <img src={levelsLogoImg} alt="Levels" className="header__logo" />

        <FiMenu
          onClick={() => setToggleMenu(!toggleMenu)}
          size={20}
          name="menu-outline"
          className="header__toggle"
          id="nav-toggle"
        ></FiMenu>

        <nav id={`nav-menu`} className={`nav ${toggleMenu ? 'show' : ''}`}>
          <div className="nav__content">
            <div className="nav__perfil">
              <span className="user__greeting">Bem-vindo,</span>
              <strong className="user__name">{user.name}</strong>
            </div>

            <FiX
              onClick={() => setToggleMenu(!toggleMenu)}
              size={24}
              name="close-outline"
              className="nav__close"
              id="nav-close"
            ></FiX>

            <div className="nav__menu">
              <ul className="nav__list">
                <NavLink
                  onClick={() => setToggleMenu(false)}
                  className="nav__link nav__item"
                  to="/dashboard"
                >
                  <FiBarChart2 size="24" />
                  Painel
                </NavLink>
                {/* <NavLink
                  onClick={() => setToggleMenu(false)}
                  className="nav__link nav__item"
                  to="/create-enterprise"
                >
                  <FiSettings />
                  Configurações
                </NavLink> */}
              </ul>
            </div>

            <button
              onClick={() => {
                setShowSelectEnterprise(!showSelectEnterprise);
                setToggleMenu(!toggleMenu);
              }}
              className="enterprise__button"
            >
              <RiBuilding2Line size="24" />
              <div className="enterprise__button-wrapper">
                <strong>{selectedEnterprise?.name ?? 'Nenhum empreendimento'}</strong>
                Trocar empreendimento
              </div>
            </button>
          </div>
        </nav>
      </S.Container>

      {showSelectEnterprise && (
        <SelectEnterprise
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          modalIsOpen={showSelectEnterprise}
          closeModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default Header;
