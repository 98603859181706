import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    background:rgba(2, 4, 9, 0.48);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity linear 0.15s;
    z-index: 2000;
    overflow-y: hidden;

    &.fade-in {
        opacity: 1;
        transition: opacity linear 0.15s;
    }
    &.fade-out {
        opacity: 0;
        transition: opacity linear 0.15s;
    }

    .modal-content {
        position: relative;
        width: 100%;
        max-width: 500px;
        min-height: 50vh;
        max-height: 90vh;
        padding: 32px;
        background: #213045;
        box-shadow: rgb(0 0 0 / 56%) 0px 5px 30px;
        border-radius: 5px;
        transform: translateY(20px);
        transition: transform 0.2s ease-in 0s, opacity 0.2s ease-in 0s;
        overflow-y: auto;
        text-align: left;
        cursor: default;

        @media(max-width: 768px) {
            min-height: 100vh;
            max-width: 100%;
            transform: translateY(0px);
            padding: 24px;
            border-radius: 0;
        }
    }
    .modal__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:0rem 0rem 1.5rem;
    }

    .modal__title {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }

    .modal-close {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border: 0px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        background: #243751;
    }
    .modal-close:hover {
        filter: brightness(1.1);
        color: rgb(255, 255, 255);
    }
    .modal-area {
        position: relative;
        padding: 20px 0;
        display: flex;
        justify-content: center;

        &:after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            top: 0px;
            background-color: #283a53;
        }
        .company__list {
            display: flex;
            list-style: none;
            flex-direction: column;
            justify-content: center;
            align-items: center ;
            row-gap: 2rem;
            width: 100%;

            .company__icon {
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #243751;
              border-radius: .5rem;

              svg {
                color: #cfe3ff;
                font-size: 1.75rem;
              }
            }

            .company__item {
                display: flex;
                align-items: center;
                column-gap: 1.5rem;
                width: 100%;
                max-width: 400px;
                background: #1d2a3c;
                padding: 1.5rem 1.5rem;
                border-radius: .75rem;
                cursor: pointer;
                transition: all 0.3s ease 0s;

                :hover {
                    background: ${lighten(0.1, '#1d2a3c')};
                }

                @media(max-width: 768px) {
                  max-width: 100%;
                }
            }

            .company__data {
                display: flex;
                row-gap: .5rem;
                flex-direction: column;
            }

            .company__name {
              font-weight: 500;
              color: #dbe0e8;
            }

            .company__address{
              color: #7f8b9b;
              font-size: 14px;
            }
        }

        .company__not-found {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 2.4rem;
          width: 100%;
          max-width: 380px;
          margin-top: 18px;

          .company__not-found-icon {
            svg {
              width: 130px;
              height: 130px;
              color: #A0ADBE;
            }
          }

          .company__not-found-message {
            text-align: center;
            color: #A0ADBE;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
        }
    }
`;