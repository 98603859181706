import styled from "styled-components";

export const Container = styled.div`
  color: #c3dcfd;
  background: #151f2e;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
  min-height: calc(100vh - var(--header-height));
  text-align: center;
  padding: 28px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const FormTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #c3dcfd;

  display: flex;
  align-items: center;
`;

export const FormGroup = styled.section`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  gap: 8px;

  @media (min-width: 768px) {
    grid-auto-flow: column;
    gap: 20px;
  }
`;

export const SideNavigation = styled.nav`
  display: none;
  background: #213045;
  width: 373px;
  height: 100%;
  margin-right: 64px;
  border-radius: 8px;
  padding: 12px 0px;

  .nav__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    height: 50px;
    width: 100%;
    color: #fff;
    text-decoration: none;

    &.nav__active {
      border-left: 2px solid #1f95fd;
      background: #2a394f;
      font-weight: 500;
    }

    &.nav__disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .nav__soon {
      font-size: 1.4rem;
      opacity: 0.5;
    }

    :hover {
      background: #2a394f;
    }
  }

  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;

  button {
    margin-top: 32px;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    max-width: 920px;
    height: 100%;
    width: 100%;
    padding: 3.2rem;
    border-radius: 8px;
    background: #213045;

    button {
      width: 182px;
    }
  }
`;
