/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { MdOutlineStackedLineChart } from 'react-icons/md';
import { ResponsiveLine } from '@nivo/line';
import { CustomTooltip } from './styles';
import { api as waterTankApi } from '../../services/waterTankApi';
import { format } from 'date-fns';
import { WaterTank } from '../../contexts/waterTank';
import formatISO from 'date-fns/formatISO';
import subHours from 'date-fns/subHours';

type ChartValue = number | string | undefined;

interface graphicData {
  x: string;
  y: number;
}

const WatertankGraphic: React.FC = (props: any) => {
  const formatChartValue = (value: ChartValue): string => `${value || 0}%`;
  const [watertankData, setWatertankData] = useState([]);
  const [watertank, setWatertank] = useState<WaterTank>();
  const [tickValues, setTickValues] = useState<graphicData[]>([]);
  const today = new Date();

  useEffect(() => {
    setWatertank(props.location.state.watertank);
  }, [props.location.state.watertank]);

  useEffect(() => {
    waterTankApi
      .get(
        `/water-tanks/${props.location.state.watertank.id}/data?start_date=${formatISO(
          subHours(today, 24)
        )}&end_date=${formatISO(today)}&limit=6000&sort_field=created_at&sort_order=asc`
      )
      .then((response: any) => {
        const filteredData = response.data.map((e: any) => {
          return {
            x: format(new Date(e.created_at), 'HH:mm:ss'),
            y: e.filter_percentage,
          };
        });

        setWatertankData(filteredData);
      });
  }, [props.location.state.watertank.id]);

  return (
    <S.Container>
      <S.FitContent>
        <S.Header statusBomb={watertank ? watertank.online : false}>
          <div>
            <p>Dispositivo</p>
            <h2>{watertank && watertank.name}</h2>
          </div>
          <span className="connection_status">
            {watertank && watertank.online ? 'Conectado' : 'Offline'}
          </span>
        </S.Header>
        <S.SubHeader>
          <div>
            <p>Nível atual</p>
            <h1>{watertank && watertank.water_tank_data.percentage}%</h1>
          </div>
          <div className="nivel__icon">
            <MdOutlineStackedLineChart size={20} />
          </div>
        </S.SubHeader>
        <S.Graphic>
          <div>
            <h1>Últimas 24 horas</h1>
          </div>
          <ResponsiveLine
            data={[
              {
                id: 'nível',
                data: watertankData,
              },
            ]}
            useMesh
            enableArea
            enableCrosshair={true}
            margin={{ top: 50, right: 0, bottom: 50, left: 30 }}
            xScale={{ type: 'point' }}
            colors={['#267FFF']}
            curve={'basis'}
            animate
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            yScale={{
              type: 'linear',
              max: 100,
              min: 0,
              reverse: false,
            }}
            tooltip={({ point }) => {
              return (
                <CustomTooltip>
                  {formatChartValue(point.data.yFormatted) + point.data.xFormatted}
                </CustomTooltip>
              );
            }}
            axisTop={null}
            axisRight={null}
            theme={{
              textColor: '#fff',
              crosshair: {
                line: {
                  stroke: '#fff',
                },
              },
            }}
            axisBottom={{
              tickValues: tickValues,
              tickSize: 12,
              tickPadding: 8,
              tickRotation: 0,
            }}
            lineWidth={2}
            pointSize={0.01}
            enablePoints
            pointLabel="y"
            pointLabelYOffset={-12}
            enableGridY={true}
            enableGridX={false}
          />
        </S.Graphic>
        {/* <S.History>
        <h1>Histórico recente</h1>
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Horário</th>
              <th>Nível</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>12 Março</td>
              <td>10h22</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>12 Março</td>
              <td>10h22</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>12 Março</td>
              <td>10h22</td>
              <td>25%</td>
            </tr>
          </tbody>
        </table>
      </S.History> */}
      </S.FitContent>
    </S.Container>
  );
};

export default WatertankGraphic;
