import styled from "styled-components";

export const Container = styled("div")`
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;

    .waves {    
        width: 100%;
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: -1;
    }

    .logo {
        width: 50px;
        margin-bottom: 16px;
        margin-top: -80px;
    }
`;

export const Card = styled("div")`
    width: calc(100% - 40px);
    max-width: 450px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 7px #e5effe;
    padding: 48px;

    @media(max-width: 425px) {
        padding: 64px 32px;
    }
`;

export const CardHeader = styled("div")`
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;

    h1 {
        font-size: 2rem;
        font-weight: 500;
        color: #31507d;
        margin-bottom: 8px;
        
        strong {
            font-weight: 600;
        }
    }

    span {
        font-size: 1.4rem;
        font-weight: 400;
        color: #adbacc;
    }
`;

export const Form = styled("form")`
    display: flex;
    flex-direction: column;

    div.textfield-container:first-child {
        margin-bottom: 16px;
    }

    > button {
        margin-top: 24px;
    }
`;

export const ForgotPassword = styled("div")`
    margin: 0 auto;
    text-align: center;
    margin-top: 24px;
    font-size: 1.4rem;

    span {
        color: #adbacc;
        font-weight: 400;
    }

    a {
        font-weight: 400;
        color: #267fff;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.4rem;

        :hover {
            filter: brightness(120%);
        }
    }
`;