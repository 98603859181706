import styled from "styled-components";

export const Container = styled('div')`
 span {
     color: #ff3b3b;
     display: flex;
     margin-top: 8px;
     font-size: 1.4rem;
 }
`;

export const TextFieldContainer = styled.div<{hasError: boolean}>`
    display: flex;
    align-items: center;
    background: #ffffff;
    width: 100%;
    height: 56px;
    border: 1px solid ${props => props.hasError ? '#ff3b3b': '#f0f6ff'};
    border-radius: 8px;
    padding: 8px 16px;

    > svg {
        color: ${props => props.hasError ? '#ff3b3b': '#267fff'};
        font-size: 2rem;
    }

    > input {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 16px;
        color: #31507d;
        outline: none;
        background: transparent;

        ::placeholder {
            color: #667e9f;
            opacity: 0.4;
        }
    }

    :focus-within {
        border: 1px solid ${props => props.hasError ? '#ff3b3b': '#267fff'};
        transition: border 300ms ease-in-out;
    }
`;