import { FiLogOut } from 'react-icons/fi';
import * as S from './styles';
import { handleLogout } from '../../contexts/auth';

export default function LogoutButton({ ...rest }: any) {
  return (
    <S.Container onClick={handleLogout} {...rest}>
      <FiLogOut />
    </S.Container>
  );
}
