import * as S from "./styles";

import { cpf, cep, telefone } from "../../utils/Mask";
import { useCallback } from "react";

export default function FormInput({
  errorMessage = null,
  fieldTitle,
  mask = null,
  disabled = false,
  ...props
}: any) {
  const handleKeyUp = useCallback(
    (e: {
      currentTarget:
        | { maxLength: number; value: any }
        | { maxLength: number; value: any }
        | { maxLength: number; value: any };
    }) => {
      if (mask === "cep") {
        return cep(e);
      }
      if (mask === "cpf") {
        return cpf(e);
      }

      if (mask === "telefone") {
        return telefone(e);
      }
    },
    [mask]
  );

  return (
    <S.Container className="field">
      <S.FieldTitle className="field__title">{fieldTitle}</S.FieldTitle>

      <S.TextFieldContainer
        className="field__container"
        disabled={disabled}
        hasError={errorMessage !== null}
      >
        <input
          className="field__input"
          autoComplete="off"
          onKeyUp={handleKeyUp}
          type="text"
          disabled={disabled}
          {...props}
        />
      </S.TextFieldContainer>
      {errorMessage && <span className="field__error">{errorMessage}</span>}
    </S.Container>
  );
}
