import styled, { css } from 'styled-components';

type CustomTooltipProps = {
  rightArrow?: boolean;
  leftArrow?: boolean;
};

export const CustomTooltip = styled.div<CustomTooltipProps>`
  ${({ theme, rightArrow, leftArrow }) => css`
    background: #fff;
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    font-size: 1rem;
    text-align: center;
    box-shadow: 0 -0.1rem 0.2rem rgba(0, 0, 0, 0.28);
  `}
`;

export const Container = styled.div`
  width: 100%;
  background: #151f2e;
  min-height: calc(100vh - var(--header-height));
  justify-content: center;
  display: flex;
`;

export const FitContent = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 0 1.6rem;
  margin-top: 48px;
`;

export const Header = styled.header<{ statusBomb: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #c3dcfd;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .connection_status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    background: ${(props) => (props.statusBomb ? '#1cc34b' : '#FD1E53')};
    border-radius: 8px;
    color: #ffffff;
  }
`;

export const SubHeader = styled.div`
  width: 100%;
  background: #293a51;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    color: #fff;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #c3dcfd;
    }

    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: #ffffff;
      margin-top: 8px;
    }
  }

  .nivel__icon {
    background: #267fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
`;

export const Graphic = styled.div`
  width: 100%;
  height: 420px;
  margin-bottom: 32px;

  div {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 48px;
    }
  }
`;

export const History = styled.div`
  margin-top: 84px;

  h1 {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  table {
    width: 100%;
    color: #ffffff;

    th {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
    }

    tr {
      border-bottom: 1px solid #35465e;
    }

    tr {
      padding: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      td {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: #c3dcfd;
      }
    }
  }
`;

export const DateButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
`;
